




import Discriminator from "@/types/Discriminator";
import JobRoleOverview from "../../components/job-role/JobRoleOverview.vue"; 
import Vue from "vue";

export default Vue.extend({
  components: {
    JobRoleOverview
  },
  data() {
    return {
      discriminator: Discriminator.VESSELS
    }
  }
})
